<template>
    <div class="navMenu">
        <template v-for="navMenu in navMenus">
            <!-- 最后一级菜单 -->
            <el-menu-item
                v-if="!navMenu.childs && navMenu.id && navMenu.is_show"
                :key="navMenu.id"
                :data="navMenu"
                :index="navMenu.path"
            >
                <span slot="title">{{ navMenu.alias }}</span>
            </el-menu-item>

            <!-- 此菜单下还有子菜单 -->
            <el-submenu
                v-if="navMenu.childs && navMenu.id && navMenu.is_show"
                :key="navMenu.id"
                :data="navMenu"
                :index="navMenu.path"
            >
                <template slot="title">
                    <span>{{ navMenu.alias }}</span>
                </template>
                <!-- 递归 -->
                <NavMenu :navMenus="navMenu.childs"></NavMenu>
            </el-submenu>
        </template>
    </div>
</template>
<style>
.navMenu .el-menu-item, .navMenu .el-submenu__title{
    height: 2.9167vw !important;
    line-height: 2.9167vw !important;
}

</style>
<script>
export default {
    name: "NavMenu",
    props: ["navMenus"]
};
</script>
