import { request } from "@/request/http";

export default {
  updatePassword: (data) => {
    return request("POST", `/index.php/user/updatePassword`, data);
  },

  getDeveloperData: (data) => {
    return request("POST", `/index.php/developer/getDeveloperData`, data);
  },
  updateDeveloperData: (data) => {
    return request("POST", `/index.php/developer/updateDeveloperData`, data);
  },

  createPDF: (data) => {
    return request("POST", `/index.php/otherTest/createPDF`, data);
  },

};